import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import MapMarkers from "./MapMarkers";
import axios from "axios";
import { geolocated } from "react-geolocated";
import googleMapStyles from "./MapStyles";
import { googleMapApiKey } from "../services/const";

class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.state = {
      latLng: [],
      centerLatLng: {
        lat: this.props.latLng[0].lat,
        lng: this.props.latLng[0].lng,
      },
    };
    this.getLatLngs = this.getLatLngs.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.isGeolocationAvailable !== prevProps.isGeolocationAvailable ||
      prevProps.coords !== this.props.coords
    ) {
      this.setState({
        centerLatLng: {
          lat: this.props.coords.latitude,
          lng: this.props.coords.longitude,
        },
      });
    }
    if (
      this.props.agents !== prevProps.agents &&
      this.props.agents.length > 0
    ) {
      this.getLatLngs();
    }
  }

  componentDidMount() {
    if (this.props.isGeolocationAvailable && this.props.coords) {
      this.setState({
        centerLatLng: {
          lat: this.props.coords.latitude,
          lng: this.props.coords.longitude,
        },
      });
    }
  }

  async getLatLngs() {
    let agents = this.props.agents;
    let arr = [];
    for (let i = 0; i < agents.length; i++) {
      let companyId = agents[i].company_id;
      let address =
        agents[i].hq_address +
        " " +
        agents[i].hq_city +
        " " +
        agents[i].hq_postcode +
        " " +
        agents[i].hq_country;
      if (agents[i].lat && agents[i].lon) {
        let latLng = {
          lat: parseFloat(agents[i].lat),
          lng: parseFloat(agents[i].lon),
          companyId: companyId,
        };
        arr.push(latLng);
      } else {
        await axios
          .get(
            `https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=${encodeURI(
              address
            )}&inputtype=textquery&fields=photos,formatted_address,name,rating,opening_hours,geometry&key=${googleMapApiKey}`
          )
          .then((ret) => {
            if (ret.data.candidates.length > 0) {
              let latLng = ret.data.candidates[0].geometry.location;
              latLng.companyId = companyId;
              arr.push(latLng);
            }
          })
          .catch((err) => {
            // console.log(JSON.parse(err.));
            console.log(err.request);
          });
      }
    }
    this.setState({ latLng: arr });
  }

  render() {
    let lat = this.props.centerTo
      ? this.props.centerTo.lat
      : this.state.centerLatLng.lat;
    let lng = this.props.centerTo
      ? this.props.centerTo.lng
      : this.state.centerLatLng.lng;
    return (
      <div style={{ height: "50vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: googleMapApiKey }}
          defaultCenter={[
            this.state.centerLatLng.lat,
            this.state.centerLatLng.lng,
          ]}
          defaultZoom={2}
          style={this.props.googleMapStyles}
          center={[lat, lng]}
          options={{
            fullscreenControl: false,
            panControl: false,
            mapTypeControl: false,
            zoomControl: false,
            minZoom: 5,
            styles: googleMapStyles,
          }}
        >
          {this.state.latLng
            ? this.state.latLng.length > 0
              ? this.state.latLng.map((item, i) => {
                  return (
                    <MapMarkers
                      onClickMarker={this.props.onClickMarker}
                      key={i}
                      lat={item.lat}
                      lng={item.lng}
                      companyId={item.companyId}
                    />
                  );
                })
              : void 0
            : void 0}
        </GoogleMapReact>
      </div>
    );
  }
}

MapContainer.defaultProps = googleMapStyles;

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(MapContainer);
